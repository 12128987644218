





















import Vue from "vue";
import Component from "vue-class-component";
import {Model} from "vue-property-decorator";
import Home from "@/pages/Home.vue";

@Component({
    components: {Home}
})
export default class Notify extends Vue {
    @Model('update:showDialog')
    showDialog: boolean = true;

    get notification(): string {
        return this.$route.params.msg;
    }
}
